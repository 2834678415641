import { memo, useRef, useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { messagePropType } from "app/utils/propTypes";
import { useField } from "formik";
import "./AdvancedSelectFormik.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

import { Option, MenuList, Menu } from "./componentsOverrides";
import useOverlay from "app/utils/hooks/useOverlay";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const AdvancedSelectFormik = props => {
	const {
		id,
		selectRef,
		label,
		className,
		isRequired,
		isSearchable = true,
		icon,
		keyCountMinToOpenMenu = 0,
		popperWidth = 200,
		components,
		menuPortalMargin = 20,
		captureMenuScroll = true,
		menuShouldBlockScroll = false,
		componentToOverlayRef = {},
		overlayClassNames,
		loading,
		popperOffset = [],
		...restProps
	} = props;
	const [field, meta, helpers] = useField(props);
	const { setValue, setTouched, setError } = helpers;
	const [targetValue, setTargetValue] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const inputRef = useRef();
	const mobileMenuWidth = inputRef?.current?.offsetWidth;
	const [isSelectFocused, setIsSelectFocused] = useState(false);

	const isTouched = (meta.touched && (!meta.error || targetValue !== "")) || field.value;

	const inputClassName = classNames("advanced-select", className, {
		"advanced-select--required": isRequired,
		"advanced-select--touched": isTouched,
		"advanced-select--error": meta.touched && meta.error,
		"advanced-select--with-icon": icon,
	});

	const handleFocus = useCallback(() => {
		if (isSearchable) {
			setIsSelectFocused(true);
		}
		setTouched(true);
		setError();
	}, []);

	const setUntouchedOnBlur = useCallback(
		event => {
			if (isSearchable) {
				setIsSelectFocused(false);
			}

			// quand le user clique ailleurs  et le champ est vide (il a rien sélectionné) ou que event.target.value est vide (il a rien tapé)
			// on met à jour la valuer de TargetValue et on set touched à false car ce dernier ne contient pas de value
			if (event.target.value === "" || !field.value) {
				setTargetValue("");
				setTouched(false);
			}
		},
		[field.value]
	);

	const handleChange = useCallback(option => {
		setValue(option);
	}, []);

	const [menuIsOpen, setMenuOpen] = useState(false);

	const handleInputChange = useCallback(values => {
		setMenuOpen(values.length >= keyCountMinToOpenMenu);
	}, []);

	if (keyCountMinToOpenMenu > 0) {
		restProps.menuIsOpen = menuIsOpen;
		restProps.onInputChange = handleInputChange;
	}
	const handleKeyDown = event => {
		setTargetValue(event.target.value);
	};

	const handleMenuOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const handleMenuClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	useOverlay(componentToOverlayRef, isOpen, overlayClassNames);

	// Cette fonction simule l'événement onBlur pour que l'élément input
	// ne conserve pas le focus et le style dédié au champ lors du défilement
	const simulateOnBlurEvent = useCallback(
		e => {
			if (selectRef?.current) {
				selectRef.current.blur(e);
			}
		},
		[selectRef]
	);

	return (
		<div
			ref={inputRef}
			className={inputClassName}
			data-testid={restProps["data-testid"]}
			id={id}
		>
			{!loading ? (
				<>
					<Select
						ref={selectRef}
						isClearable={isSearchable && isSelectFocused}
						backspaceRemovesValue={true}
						className="advanced-select__select"
						classNamePrefix="advanced-select__select"
						classNames={{
							menuPortal: () => "test",
						}}
						styles={{
							dropdownIndicator: (base, state) => ({
								...base,
								transition: "all .2s ease",
								transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
							}),
							menuList: base => ({
								...base,
								minWidth: isMobile ? "none" : popperWidth,
								width: isMobile ? mobileMenuWidth : "unset",
								maxHeight: "474px",
								padding: isMobile ? "0" : "8px 0",
							}), // on limite volontairement pour afficher la moitié du 4ème pour suggérer la possibilité de scroller pour afficher la suite
							option: base => ({
								...base,
								border: "none",
								height: isMobile ? "auto" : "56px",
								display: "flex",
								alignItems: "center",
							}),
							menuPortal: provided => ({
								...provided,
								zIndex: 999,
								marginTop: menuPortalMargin,
							}),
							menu: provided => ({
								...provided,
								width: "fit-content",
								boxShadow: "none",
								borderRadius: "none",
								left: popperOffset[0] ?? 0,
								top: popperOffset[1] ?? 0,
							}),
						}}
						placeholder={null}
						blurInputOnSelect={true}
						captureMenuScroll={captureMenuScroll}
						// This prop needs to be kept as false to disable auto scrolling
						menuShouldScrollIntoView={false}
						menuShouldBlockScroll={menuShouldBlockScroll}
						closeMenuOnScroll={e => {
							// Ne pas supprimer cette condition "Cypress === "undefined"", sinon les tests e2e peuvent échouer
							if (typeof Cypress === "undefined" && e.target === document) {
								simulateOnBlurEvent(e);
								return true;
							}
							return false;
						}}
						{...field}
						onFocus={handleFocus}
						onBlur={setUntouchedOnBlur}
						onChange={handleChange}
						menuPortalTarget={typeof document !== "undefined" && document.body}
						components={{ Menu, MenuList, Option, ...components }}
						isSearchable={isSearchable}
						onKeyDown={handleKeyDown}
						onMenuOpen={handleMenuOpen}
						onMenuClose={handleMenuClose}
						{...restProps}
					/>
					{icon && <div className="advanced-select__icon">{icon}</div>}
					<label htmlFor={id} className="advanced-select__label">
						{isTouched ? (
							<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
								{label}
							</Typography>
						) : (
							<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>{label}</Typography>
						)}
					</label>
				</>
			) : (
				<div className="advanced-select__loader">
					<LoaderBar height={10} width={"80%"} />
					<LoaderBar height={10} />
				</div>
			)}
		</div>
	);
};

AdvancedSelectFormik.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	value: PropTypes.any,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onMenuOpen: PropTypes.func,
	onMenuClose: PropTypes.func,
	isRequired: PropTypes.bool,
	icon: PropTypes.element,
	keyCountMinToOpenMenu: PropTypes.number,
	popperWidth: PropTypes.number,
	components: PropTypes.object,
	menuPortalMargin: PropTypes.number,
	selectRef: PropTypes.object,
	captureMenuScroll: PropTypes.bool,
	menuShouldBlockScroll: PropTypes.bool,
	loading: PropTypes.bool,
	componentToOverlayRef: PropTypes.object,
	overlayClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	popperOffset: PropTypes.array,
};

export default memo(AdvancedSelectFormik);
